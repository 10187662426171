<template>
  <div>
    <div class="form-container">
      <div class="form-content-body pb-3">
        <div v-if="!confirmed">
          <h2 class="font-48-900 text-center dark-text mb-4">
            Confirm Account
          </h2>
          <!-- base-input -->
          <form method="POST" @submit.prevent="validate" role="form">
            <div class="password-field">
              <text-input
                label="Password"
                :type="showPassword ? 'text' : 'password'"
                v-model="password"
              />
              <span
                class="toggle-visibility"
                @click="showPassword = !showPassword"
              >
                <i :class="showPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"></i>
              </span>
            </div>

            <div class="password-field">
              <text-input
                label="Confirm Password"
                :type="showConfirmPassword ? 'text' : 'password'"
                v-model="confirmPassword"
              />
              <span
                class="toggle-visibility"
                @click="showConfirmPassword = !showConfirmPassword"
              >
                <i
                  :class="showConfirmPassword ? 'fa fa-eye-slash' : 'fa fa-eye'"
                ></i>
              </span>
            </div>

            <form-error v-show="errors && errors.password">{{
              errors && errors.password
            }}</form-error>
            <h2>Password Requirements:</h2>
            <ul>
              <li>12 characters long</li>
              <li>One or more upper-case characters</li>
              <li>One or more lower-case characters</li>
              <li>One or more numbers</li>
              <li>
                One or more special characters (@, $, !,% , *, #, ?, &, etc.)
              </li>
            </ul>
            <button
              class="btn btn-dark-white w-100 btn-form go-rounded-14 mb-4"
              type="submit"
            >
              Save
            </button>
          </form>
        </div>
        <div v-else>
          <h2 class="font-48-900 text-center dark-text mb-4">
            Confirmation Successful
          </h2>
          <p>Login to the system and complete the profile.</p>
          <router-link :to="{ name: 'login' }">
            <button
              class="btn btn-dark-white w-100 btn-form go-rounded-14 mb-4"
            >
              Back to Login
            </button>
          </router-link>
        </div>
      </div>
    </div>
    <login-footer />
  </div>
</template>
<script>
import { PASSWORD_MIN_LENGTH } from "@/utils/constants"

export default {
  name: "register",
  created() {},
  data() {
    return {
      errors: null,
      password: "",
      confirmPassword: "",
      confirmed: false,
      showPassword: false,
      showConfirmPassword: false,
    }
  },
  methods: {
    validate() {
      const password = this.password
      const confirmPassword = this.confirmPassword
      const errors = {
        ...(!password
          ? { password: "Please enter a password" }
          : password.length < PASSWORD_MIN_LENGTH
          ? {
              password: `Please enter a password at least ${PASSWORD_MIN_LENGTH} characters long`,
            }
          : password != confirmPassword
          ? { password: "Passwords do not match" }
          : !/[@$!%*#?&^()[\]{}'"\\/\-_><,.;`~]/g.test(password)
          ? { password: "Password requires at least one special character" }
          : !/[A-Z]/g.test(password)
          ? { password: "Password requires at least one upper-case character" }
          : !/[a-z]/g.test(password)
          ? { password: "Password requires at least one lower-case character" }
          : !/[\d]/g.test(password)
          ? { password: "Password requires at least one number" }
          : {}),
      }
      if (errors.password) {
        this.errors = errors
        return
      }
      this.handleSubmit()
    },
    async handleSubmit() {
      const payload = {
        userDetails: {
          emailConfirmationKey: this.$route.query?.k ?? "",
          password: this.password,
        },
      }

      try {
        const { email } = await this.$store.dispatch(
          "auth/confirmUserEmail",
          payload
        )
        this.confirmed = true
        await this.$store.dispatch("auth/login", {
          userDetails: {
            email,
            password: this.password,
          },
        })
      } catch (error) {
        console.error(error)
        this.$toast.error(error.message)
      }
    },
  },
}
</script>
<style>
.password-field {
  position: relative;
}

.toggle-visibility {
  position: absolute;
  right: 10px;
  top: 34%;
  transform: translateY(-50%);
  cursor: pointer;
}

.toggle-visibility i {
  font-size: 1.2rem;
  color: var(--Gray-900, #343946);
}
</style>
